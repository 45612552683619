/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import InstitutionAdminCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/InstitutionAdminCompletedCourseOfferingReferenceManyToMany';
import UserCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/UserCompletedCourseOfferingReferenceManyToMany';
import VisitorsCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/VisitorsCompletedCourseOfferingReferenceManyToMany';
import StudentAdvisorCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/StudentAdvisorCompletedCourseOfferingReferenceManyToMany';
import AdminCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/AdminCompletedCourseOfferingReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICompletedByStudyPlanCompletedCourseOfferingAttributes extends IModelAttributes {
	completedByStudyPlanId: string;
	completedCourseOfferingId: string;

	completedByStudyPlan: Models.StudyPlanEntity | Models.IStudyPlanEntityAttributes;
	completedCourseOffering: Models.CourseOfferingEntity | Models.ICourseOfferingEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CompletedByStudyPlanCompletedCourseOffering')
export default class CompletedByStudyPlanCompletedCourseOffering
	extends Model
	implements ICompletedByStudyPlanCompletedCourseOfferingAttributes {
	public static acls: IAcl[] = [
		new InstitutionAdminCompletedCourseOfferingReferenceManyToMany(),
		new UserCompletedCourseOfferingReferenceManyToMany(),
		new VisitorsCompletedCourseOfferingReferenceManyToMany(),
		new StudentAdvisorCompletedCourseOfferingReferenceManyToMany(),
		new AdminCompletedCourseOfferingReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public completedByStudyPlanId: string;

	@observable
	@attribute()
	public completedCourseOfferingId: string;

	@observable
	@attribute({ isReference: true })
	public completedByStudyPlan: Models.StudyPlanEntity;

	@observable
	@attribute({ isReference: true })
	public completedCourseOffering: Models.CourseOfferingEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICompletedByStudyPlanCompletedCourseOfferingAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.completedByStudyPlanId) {
				this.completedByStudyPlanId = attributes.completedByStudyPlanId;
			}
			if (attributes.completedCourseOfferingId) {
				this.completedCourseOfferingId = attributes.completedCourseOfferingId;
			}

			if (attributes.completedByStudyPlan) {
				if (attributes.completedByStudyPlan instanceof Models.StudyPlanEntity) {
					this.completedByStudyPlan = attributes.completedByStudyPlan;
					this.completedByStudyPlanId = attributes.completedByStudyPlan.id;
				} else {
					this.completedByStudyPlan = new Models.StudyPlanEntity(attributes.completedByStudyPlan);
					this.completedByStudyPlanId = this.completedByStudyPlan.id;
				}
			} else if (attributes.completedByStudyPlanId !== undefined) {
				this.completedByStudyPlanId = attributes.completedByStudyPlanId;
			}

			if (attributes.completedCourseOffering) {
				if (attributes.completedCourseOffering instanceof Models.CourseOfferingEntity) {
					this.completedCourseOffering = attributes.completedCourseOffering;
					this.completedCourseOfferingId = attributes.completedCourseOffering.id;
				} else {
					this.completedCourseOffering = new Models.CourseOfferingEntity(attributes.completedCourseOffering);
					this.completedCourseOfferingId = this.completedCourseOffering.id;
				}
			} else if (attributes.completedCourseOfferingId !== undefined) {
				this.completedCourseOfferingId = attributes.completedCourseOfferingId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
