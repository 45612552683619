/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import InstitutionAdminPlannedCourseOfferingReferenceManyToMany from '../Security/Acl/InstitutionAdminPlannedCourseOfferingReferenceManyToMany';
import AdminPlannedCourseOfferingReferenceManyToMany from '../Security/Acl/AdminPlannedCourseOfferingReferenceManyToMany';
import VisitorsPlannedCourseOfferingReferenceManyToMany from '../Security/Acl/VisitorsPlannedCourseOfferingReferenceManyToMany';
import UserPlannedCourseOfferingReferenceManyToMany from '../Security/Acl/UserPlannedCourseOfferingReferenceManyToMany';
import StudentAdvisorPlannedCourseOfferingReferenceManyToMany from '../Security/Acl/StudentAdvisorPlannedCourseOfferingReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IStudyPlanPlannedCourseOfferingAttributes extends IModelAttributes {
	studyPlanId: string;
	plannedCourseOfferingId: string;

	studyPlan: Models.StudyPlanEntity | Models.IStudyPlanEntityAttributes;
	plannedCourseOffering: Models.CourseOfferingEntity | Models.ICourseOfferingEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('StudyPlanPlannedCourseOffering')
export default class StudyPlanPlannedCourseOffering
	extends Model
	implements IStudyPlanPlannedCourseOfferingAttributes {
	public static acls: IAcl[] = [
		new InstitutionAdminPlannedCourseOfferingReferenceManyToMany(),
		new AdminPlannedCourseOfferingReferenceManyToMany(),
		new VisitorsPlannedCourseOfferingReferenceManyToMany(),
		new UserPlannedCourseOfferingReferenceManyToMany(),
		new StudentAdvisorPlannedCourseOfferingReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public studyPlanId: string;

	@observable
	@attribute()
	public plannedCourseOfferingId: string;

	@observable
	@attribute({ isReference: true })
	public studyPlan: Models.StudyPlanEntity;

	@observable
	@attribute({ isReference: true })
	public plannedCourseOffering: Models.CourseOfferingEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IStudyPlanPlannedCourseOfferingAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.studyPlanId) {
				this.studyPlanId = attributes.studyPlanId;
			}
			if (attributes.plannedCourseOfferingId) {
				this.plannedCourseOfferingId = attributes.plannedCourseOfferingId;
			}

			if (attributes.studyPlan) {
				if (attributes.studyPlan instanceof Models.StudyPlanEntity) {
					this.studyPlan = attributes.studyPlan;
					this.studyPlanId = attributes.studyPlan.id;
				} else {
					this.studyPlan = new Models.StudyPlanEntity(attributes.studyPlan);
					this.studyPlanId = this.studyPlan.id;
				}
			} else if (attributes.studyPlanId !== undefined) {
				this.studyPlanId = attributes.studyPlanId;
			}

			if (attributes.plannedCourseOffering) {
				if (attributes.plannedCourseOffering instanceof Models.CourseOfferingEntity) {
					this.plannedCourseOffering = attributes.plannedCourseOffering;
					this.plannedCourseOfferingId = attributes.plannedCourseOffering.id;
				} else {
					this.plannedCourseOffering = new Models.CourseOfferingEntity(attributes.plannedCourseOffering);
					this.plannedCourseOfferingId = this.plannedCourseOffering.id;
				}
			} else if (attributes.plannedCourseOfferingId !== undefined) {
				this.plannedCourseOfferingId = attributes.plannedCourseOfferingId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
