/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminGeneratedPlanEntity from 'Models/Security/Acl/AdminGeneratedPlanEntity';
import StudentAdvisorGeneratedPlanEntity from 'Models/Security/Acl/StudentAdvisorGeneratedPlanEntity';
import InstitutionAdminGeneratedPlanEntity from 'Models/Security/Acl/InstitutionAdminGeneratedPlanEntity';
import UserGeneratedPlanEntity from 'Models/Security/Acl/UserGeneratedPlanEntity';
import VisitorsGeneratedPlanEntity from 'Models/Security/Acl/VisitorsGeneratedPlanEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGeneratedPlanEntityAttributes extends IModelAttributes {
	commencement: Date;
	startingTerm: number;

	generatedCompletedCourseOfferings: Array<
		| Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering
		| Models.ICompletedByGeneratedPlanGeneratedCompletedCourseOfferingAttributes
	>;
	studyPlanId: string;
	studyPlan: Models.StudyPlanEntity | Models.IStudyPlanEntityAttributes;
	terms: Array<
		| Models.GeneratedTermEntity
		| Models.IGeneratedTermEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GeneratedPlanEntity', 'Generated Plan')
// % protected region % [Customise your entity metadata here] end
export default class GeneratedPlanEntity extends Model
	implements IGeneratedPlanEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminGeneratedPlanEntity(),
		new StudentAdvisorGeneratedPlanEntity(),
		new InstitutionAdminGeneratedPlanEntity(),
		new UserGeneratedPlanEntity(),
		new VisitorsGeneratedPlanEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Commencement'] off begin
	@Validators.Required()
	@observable
	@attribute<GeneratedPlanEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Commencement',
		displayType: 'datepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public commencement: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Commencement'] end

	// % protected region % [Modify props to the crud options here for attribute 'Starting Term'] off begin
	@Validators.Integer()
	@observable
	@attribute<GeneratedPlanEntity, number>()
	@CRUD({
		name: 'Starting Term',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public startingTerm: number;
	// % protected region % [Modify props to the crud options here for attribute 'Starting Term'] end

	/**
	 * Course Offering that have been completed prior to the commencement of the Generated Plan
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Generated Completed Course Offering'] off begin
		name: 'Generated Completed Course Offering',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering,
		optionEqualFunc: makeJoinEqualsFunc('generatedCompletedCourseOfferingId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'generatedPlanEntity',
			oppositeEntityName: 'courseOfferingEntity',
			relationName: 'completedByGeneratedPlan',
			relationOppositeName: 'generatedCompletedCourseOffering',
			entity: () => Models.GeneratedPlanEntity,
			joinEntity: () => Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering,
			oppositeEntity: () => Models.CourseOfferingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Generated Completed Course Offering'] end
	})
	public generatedCompletedCourseOfferings: Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] off begin
		name: 'Study Plan',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.StudyPlanEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyPlans',
			oppositeEntity: () => Models.StudyPlanEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] end
	})
	public studyPlanId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public studyPlan: Models.StudyPlanEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term'] off begin
		name: 'Terms',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.GeneratedTermEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'terms',
			oppositeEntity: () => Models.GeneratedTermEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Term'] end
	})
	public terms: Models.GeneratedTermEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGeneratedPlanEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGeneratedPlanEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.commencement !== undefined) {
				if (attributes.commencement === null) {
					this.commencement = attributes.commencement;
				} else {
					this.commencement = moment(attributes.commencement).toDate();
				}
			}
			if (attributes.startingTerm !== undefined) {
				this.startingTerm = attributes.startingTerm;
			}
			if (attributes.generatedCompletedCourseOfferings !== undefined && Array.isArray(attributes.generatedCompletedCourseOfferings)) {
				for (const model of attributes.generatedCompletedCourseOfferings) {
					if (model instanceof Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering) {
						this.generatedCompletedCourseOfferings.push(model);
					} else {
						this.generatedCompletedCourseOfferings.push(new Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering(model));
					}
				}
			}
			if (attributes.studyPlanId !== undefined) {
				this.studyPlanId = attributes.studyPlanId;
			}
			if (attributes.studyPlan !== undefined) {
				if (attributes.studyPlan === null) {
					this.studyPlan = attributes.studyPlan;
				} else if (attributes.studyPlan instanceof Models.StudyPlanEntity) {
					this.studyPlan = attributes.studyPlan;
					this.studyPlanId = attributes.studyPlan.id;
				} else {
					this.studyPlan = new Models.StudyPlanEntity(attributes.studyPlan);
					this.studyPlanId = this.studyPlan.id;
				}
			}
			if (attributes.terms !== undefined && Array.isArray(attributes.terms)) {
				for (const model of attributes.terms) {
					if (model instanceof Models.GeneratedTermEntity) {
						this.terms.push(model);
					} else {
						this.terms.push(new Models.GeneratedTermEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		generatedCompletedCourseOfferings {
			${Models.CompletedByGeneratedPlanGeneratedCompletedCourseOffering.getAttributes().join('\n')}
			generatedCompletedCourseOffering {
				${Models.CourseOfferingEntity.getAttributes().join('\n')}
			}
		}
		studyPlan {
			${Models.StudyPlanEntity.getAttributes().join('\n')}
		}
		terms {
			${Models.GeneratedTermEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			generatedCompletedCourseOfferings: {},
			terms: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'generatedCompletedCourseOfferings',
							'studyPlan',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GeneratedPlanEntity.prototype, 'created');
CRUD(modifiedAttr)(GeneratedPlanEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
