/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import InstitutionAdminCourseEntity from 'Models/Security/Acl/InstitutionAdminCourseEntity';
import VisitorsCourseEntity from 'Models/Security/Acl/VisitorsCourseEntity';
import AdminCourseEntity from 'Models/Security/Acl/AdminCourseEntity';
import UserCourseEntity from 'Models/Security/Acl/UserCourseEntity';
import StudentAdvisorCourseEntity from 'Models/Security/Acl/StudentAdvisorCourseEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { indexByPropertyMutate } from 'Util/DataIndexUtils';
// % protected region % [Add any further imports here] end

export interface ICourseEntityAttributes extends IModelAttributes {
	name: string;
	level: string;
	courseId: string;
	description: string;
	units: number;
	code: string;
	shortName: string;

	campuss: Array<
		| Models.CampusCourseCampus
		| Models.ICampusCourseCampusAttributes
	>;
	graduateLevels: Array<
		| Models.GraduateLevelCourseGraduateLevel
		| Models.IGraduateLevelCourseGraduateLevelAttributes
	>;
	courseOfferings: Array<
		| Models.CourseOfferingEntity
		| Models.ICourseOfferingEntityAttributes
	>;
	terms: Array<
		| Models.TermCourseTerm
		| Models.ITermCourseTermAttributes
	>;
	courseLevelId?: string;
	courseLevel?: Models.CourseLevelEntity | Models.ICourseLevelEntityAttributes;
	courseSets: Array<
		| Models.CourseSetCourse
		| Models.ICourseSetCourseAttributes
	>;
	disciplineId?: string;
	discipline?: Models.DisciplineEntity | Models.IDisciplineEntityAttributes;
	institutionId: string;
	institution: Models.InstitutionEntity | Models.IInstitutionEntityAttributes;
	programRules: Array<
		| Models.ProgramRuleCourse
		| Models.IProgramRuleCourseAttributes
	>;
	requiredByCourses: Array<
		| Models.RequisiteCourseEntity
		| Models.IRequisiteCourseEntityAttributes
	>;
	requisiteLogicGroups: Array<
		| Models.LogicGroupEntity
		| Models.ILogicGroupEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CourseEntity', 'Course')
// % protected region % [Customise your entity metadata here] end
export default class CourseEntity extends Model
	implements ICourseEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new InstitutionAdminCourseEntity(),
		new VisitorsCourseEntity(),
		new AdminCourseEntity(),
		new UserCourseEntity(),
		new StudentAdvisorCourseEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	/**
	 * The name of the course.
	 */
	@Validators.Required('Course title is required')
	@observable
	@attribute<CourseEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Level'] on begin
	/**
	 * Introductory, Graduate...
	 */
	@observable
	@attribute<CourseEntity, string>()
	@CRUD({
		name: 'Level',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public level: string;
	// % protected region % [Modify props to the crud options here for attribute 'Level'] end

	// % protected region % [Modify props to the crud options here for attribute 'Course Id'] on begin
	/**
	 * This recognises a unique instance of a course from an institutions data model
	 */
	@Validators.Numeric()
	@Validators.Required('Course ID is required')
	@observable
	@attribute<CourseEntity, string>()
	@CRUD({
		name: 'Course Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public courseId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Course Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	@observable
	@attribute<CourseEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Units'] on begin
	/**
	 * The number of units contributed by this course.
	 */
	@Validators.Required('Unit is required')
	@Validators.Min(0)
	@Validators.Max(999999999)
	@Validators.Numeric()
	@observable
	@attribute<CourseEntity, number>()
	@CRUD({
		name: 'Units',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public units: number;
	// % protected region % [Modify props to the crud options here for attribute 'Units'] end

	// % protected region % [Modify props to the crud options here for attribute 'Code'] on begin
	/**
	 * Course code
	 */
	@Validators.Required('Course code is required')
	@observable
	@attribute<CourseEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Short Name'] on begin
	/**
	 * Course short title
	 */
	@observable
	@attribute<CourseEntity, string>()
	@CRUD({
		name: 'Short Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public shortName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Short Name'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Campus'] off begin
		name: 'Campus',
		displayType: 'reference-multicombobox',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CampusCourseCampus,
		optionEqualFunc: makeJoinEqualsFunc('campusId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'courseEntity',
			oppositeEntityName: 'campusEntity',
			relationName: 'courseCampus',
			relationOppositeName: 'campus',
			entity: () => Models.CourseEntity,
			joinEntity: () => Models.CampusCourseCampus,
			oppositeEntity: () => Models.CampusEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Campus'] end
	})
	public campuss: Models.CampusCourseCampus[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Graduate Level'] off begin
		name: 'Graduate Level',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.GraduateLevelCourseGraduateLevel,
		optionEqualFunc: makeJoinEqualsFunc('graduateLevelId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'courseEntity',
			oppositeEntityName: 'graduateLevelEntity',
			relationName: 'courseGraduateLevel',
			relationOppositeName: 'graduateLevel',
			entity: () => Models.CourseEntity,
			joinEntity: () => Models.GraduateLevelCourseGraduateLevel,
			oppositeEntity: () => Models.GraduateLevelEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Graduate Level'] end
	})
	public graduateLevels: Models.GraduateLevelCourseGraduateLevel[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] off begin
		name: 'Course Offerings',
		displayType: 'reference-multicombobox',
		order: 100,
		referenceTypeFunc: () => Models.CourseOfferingEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'courseOfferings',
			oppositeEntity: () => Models.CourseOfferingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] end
	})
	public courseOfferings: Models.CourseOfferingEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Term'] off begin
		name: 'Term',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TermCourseTerm,
		optionEqualFunc: makeJoinEqualsFunc('termId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'courseEntity',
			oppositeEntityName: 'termEntity',
			relationName: 'courseTerm',
			relationOppositeName: 'term',
			entity: () => Models.CourseEntity,
			joinEntity: () => Models.TermCourseTerm,
			oppositeEntity: () => Models.TermEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Term'] end
	})
	public terms: Models.TermCourseTerm[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Level'] off begin
		name: 'Course Level',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.CourseLevelEntity,
		// % protected region % [Modify props to the crud options here for reference 'Course Level'] end
	})
	public courseLevelId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public courseLevel: Models.CourseLevelEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Set'] off begin
		name: 'Course Set',
		displayType: 'reference-multicombobox',
		order: 130,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.CourseSetCourse,
		optionEqualFunc: makeJoinEqualsFunc('courseSetId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'courseEntity',
			oppositeEntityName: 'courseSetEntity',
			relationName: 'course',
			relationOppositeName: 'courseSet',
			entity: () => Models.CourseEntity,
			joinEntity: () => Models.CourseSetCourse,
			oppositeEntity: () => Models.CourseSetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course Set'] end
	})
	public courseSets: Models.CourseSetCourse[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Discipline'] off begin
		name: 'Discipline',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.DisciplineEntity,
		// % protected region % [Modify props to the crud options here for reference 'Discipline'] end
	})
	public disciplineId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public discipline: Models.DisciplineEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution'] off begin
		name: 'Institution',
		displayType: 'reference-combobox',
		order: 150,
		referenceTypeFunc: () => Models.InstitutionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Institution'] end
	})
	public institutionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institution: Models.InstitutionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Rule'] off begin
		name: 'Program Rule',
		displayType: 'reference-multicombobox',
		order: 160,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProgramRuleCourse,
		optionEqualFunc: makeJoinEqualsFunc('programRuleId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'courseEntity',
			oppositeEntityName: 'programRuleEntity',
			relationName: 'course',
			relationOppositeName: 'programRule',
			entity: () => Models.CourseEntity,
			joinEntity: () => Models.ProgramRuleCourse,
			oppositeEntity: () => Models.ProgramRuleEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Rule'] end
	})
	public programRules: Models.ProgramRuleCourse[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Required By Course'] off begin
		name: 'Required By Courses',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.RequisiteCourseEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'requiredByCourses',
			oppositeEntity: () => Models.RequisiteCourseEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Required By Course'] end
	})
	public requiredByCourses: Models.RequisiteCourseEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Requisite Logic Group'] off begin
		name: 'Requisite Logic Groups',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.LogicGroupEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'requisiteLogicGroups',
			oppositeEntity: () => Models.LogicGroupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Requisite Logic Group'] end
	})
	public requisiteLogicGroups: Models.LogicGroupEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICourseEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICourseEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.level !== undefined) {
				this.level = attributes.level;
			}
			if (attributes.courseId !== undefined) {
				this.courseId = attributes.courseId;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.units !== undefined) {
				this.units = attributes.units;
			}
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.shortName !== undefined) {
				this.shortName = attributes.shortName;
			}
			if (attributes.campuss !== undefined && Array.isArray(attributes.campuss)) {
				for (const model of attributes.campuss) {
					if (model instanceof Models.CampusCourseCampus) {
						this.campuss.push(model);
					} else {
						this.campuss.push(new Models.CampusCourseCampus(model));
					}
				}
			}
			if (attributes.graduateLevels !== undefined && Array.isArray(attributes.graduateLevels)) {
				for (const model of attributes.graduateLevels) {
					if (model instanceof Models.GraduateLevelCourseGraduateLevel) {
						this.graduateLevels.push(model);
					} else {
						this.graduateLevels.push(new Models.GraduateLevelCourseGraduateLevel(model));
					}
				}
			}
			if (attributes.courseOfferings !== undefined && Array.isArray(attributes.courseOfferings)) {
				for (const model of attributes.courseOfferings) {
					if (model instanceof Models.CourseOfferingEntity) {
						this.courseOfferings.push(model);
					} else {
						this.courseOfferings.push(new Models.CourseOfferingEntity(model));
					}
				}
			}
			if (attributes.terms !== undefined && Array.isArray(attributes.terms)) {
				for (const model of attributes.terms) {
					if (model instanceof Models.TermCourseTerm) {
						this.terms.push(model);
					} else {
						this.terms.push(new Models.TermCourseTerm(model));
					}
				}
			}
			if (attributes.courseLevelId !== undefined) {
				this.courseLevelId = attributes.courseLevelId;
			}
			if (attributes.courseLevel !== undefined) {
				if (attributes.courseLevel === null) {
					this.courseLevel = attributes.courseLevel;
				} else if (attributes.courseLevel instanceof Models.CourseLevelEntity) {
					this.courseLevel = attributes.courseLevel;
					this.courseLevelId = attributes.courseLevel.id;
				} else {
					this.courseLevel = new Models.CourseLevelEntity(attributes.courseLevel);
					this.courseLevelId = this.courseLevel.id;
				}
			}
			if (attributes.courseSets !== undefined && Array.isArray(attributes.courseSets)) {
				for (const model of attributes.courseSets) {
					if (model instanceof Models.CourseSetCourse) {
						this.courseSets.push(model);
					} else {
						this.courseSets.push(new Models.CourseSetCourse(model));
					}
				}
			}
			if (attributes.disciplineId !== undefined) {
				this.disciplineId = attributes.disciplineId;
			}
			if (attributes.discipline !== undefined) {
				if (attributes.discipline === null) {
					this.discipline = attributes.discipline;
				} else if (attributes.discipline instanceof Models.DisciplineEntity) {
					this.discipline = attributes.discipline;
					this.disciplineId = attributes.discipline.id;
				} else {
					this.discipline = new Models.DisciplineEntity(attributes.discipline);
					this.disciplineId = this.discipline.id;
				}
			}
			if (attributes.institutionId !== undefined) {
				this.institutionId = attributes.institutionId;
			}
			if (attributes.institution !== undefined) {
				if (attributes.institution === null) {
					this.institution = attributes.institution;
				} else if (attributes.institution instanceof Models.InstitutionEntity) {
					this.institution = attributes.institution;
					this.institutionId = attributes.institution.id;
				} else {
					this.institution = new Models.InstitutionEntity(attributes.institution);
					this.institutionId = this.institution.id;
				}
			}
			if (attributes.programRules !== undefined && Array.isArray(attributes.programRules)) {
				for (const model of attributes.programRules) {
					if (model instanceof Models.ProgramRuleCourse) {
						this.programRules.push(model);
					} else {
						this.programRules.push(new Models.ProgramRuleCourse(model));
					}
				}
			}
			if (attributes.requiredByCourses !== undefined && Array.isArray(attributes.requiredByCourses)) {
				for (const model of attributes.requiredByCourses) {
					if (model instanceof Models.RequisiteCourseEntity) {
						this.requiredByCourses.push(model);
					} else {
						this.requiredByCourses.push(new Models.RequisiteCourseEntity(model));
					}
				}
			}
			if (attributes.requisiteLogicGroups !== undefined && Array.isArray(attributes.requisiteLogicGroups)) {
				for (const model of attributes.requisiteLogicGroups) {
					if (model instanceof Models.LogicGroupEntity) {
						this.requisiteLogicGroups.push(model);
					} else {
						this.requisiteLogicGroups.push(new Models.LogicGroupEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		campuss {
			${Models.CampusCourseCampus.getAttributes().join('\n')}
			campus {
				${Models.CampusEntity.getAttributes().join('\n')}
			}
		}
		graduateLevels {
			${Models.GraduateLevelCourseGraduateLevel.getAttributes().join('\n')}
			graduateLevel {
				${Models.GraduateLevelEntity.getAttributes().join('\n')}
			}
		}
		terms {
			${Models.TermCourseTerm.getAttributes().join('\n')}
			term {
				${Models.TermEntity.getAttributes().join('\n')}
			}
		}
		courseSets {
			${Models.CourseSetCourse.getAttributes().join('\n')}
			courseSet {
				${Models.CourseSetEntity.getAttributes().join('\n')}
			}
		}
		programRules {
			${Models.ProgramRuleCourse.getAttributes().join('\n')}
			programRule {
				${Models.ProgramRuleEntity.getAttributes().join('\n')}
			}
		}
		courseOfferings {
			${Models.CourseOfferingEntity.getAttributes().join('\n')}
		}
		courseLevel {
			${Models.CourseLevelEntity.getAttributes().join('\n')}
		}
		discipline {
			${Models.DisciplineEntity.getAttributes().join('\n')}
		}
		institution {
			${Models.InstitutionEntity.getAttributes().join('\n')}
			${Models.InstitutionEntity.getFiles().map(f => f.name).join('\n')}
		}
		requiredByCourses {
			${Models.RequisiteCourseEntity.getAttributes().join('\n')}
		}
		requisiteLogicGroups {
			${Models.LogicGroupEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			campuss: {},
			graduateLevels: {},
			terms: {},
			courseSets: {},
			programRules: {},
			courseOfferings: {},
			requiredByCourses: {},
			requisiteLogicGroups: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'campuss',
							'graduateLevels',
							'terms',
							'courseSets',
							'programRules',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getStudyPeriods(): Models.StudyPeriodEntity[] {
		if (this.terms === undefined) {
			throw new Error("Terms not initialized for course");
		}

		const distinctStudyPeriodIndex = indexByPropertyMutate<Models.TermCourseTerm, Models.StudyPeriodEntity>(
			this.terms,
			termCourse => {
				if (termCourse.term.studyPeriod?.id === undefined) {
					throw new Error("Study period ID not initialized for term assigned to course");
				}

				return termCourse.term.studyPeriod.id
			},
			termCourse => {
				if (termCourse.term.studyPeriod === undefined) {
					throw new Error("Study period not initialized for term assigned to course");
				}

				return termCourse.term.studyPeriod;
			},
		);

		const distinctStudyPeriods = Object.values(distinctStudyPeriodIndex);

		return distinctStudyPeriods;
	};

	public static compareCodeAscending(a: CourseEntity, b: CourseEntity) {
		if (a.code === undefined || b.code === undefined) {
			throw new Error('Cannot compare Courses by code - code is not defined');
		}

		return a.code.localeCompare(b.code);
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CourseEntity.prototype, 'created');
CRUD(modifiedAttr)(CourseEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
