/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AdminGeneratedCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/AdminGeneratedCompletedCourseOfferingReferenceManyToMany';
import VisitorsGeneratedCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/VisitorsGeneratedCompletedCourseOfferingReferenceManyToMany';
import StudentAdvisorGeneratedCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/StudentAdvisorGeneratedCompletedCourseOfferingReferenceManyToMany';
import InstitutionAdminGeneratedCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/InstitutionAdminGeneratedCompletedCourseOfferingReferenceManyToMany';
import UserGeneratedCompletedCourseOfferingReferenceManyToMany from '../Security/Acl/UserGeneratedCompletedCourseOfferingReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICompletedByGeneratedPlanGeneratedCompletedCourseOfferingAttributes extends IModelAttributes {
	completedByGeneratedPlanId: string;
	generatedCompletedCourseOfferingId: string;

	completedByGeneratedPlan: Models.GeneratedPlanEntity | Models.IGeneratedPlanEntityAttributes;
	generatedCompletedCourseOffering: Models.CourseOfferingEntity | Models.ICourseOfferingEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('CompletedByGeneratedPlanGeneratedCompletedCourseOffering')
export default class CompletedByGeneratedPlanGeneratedCompletedCourseOffering
	extends Model
	implements ICompletedByGeneratedPlanGeneratedCompletedCourseOfferingAttributes {
	public static acls: IAcl[] = [
		new AdminGeneratedCompletedCourseOfferingReferenceManyToMany(),
		new VisitorsGeneratedCompletedCourseOfferingReferenceManyToMany(),
		new StudentAdvisorGeneratedCompletedCourseOfferingReferenceManyToMany(),
		new InstitutionAdminGeneratedCompletedCourseOfferingReferenceManyToMany(),
		new UserGeneratedCompletedCourseOfferingReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public completedByGeneratedPlanId: string;

	@observable
	@attribute()
	public generatedCompletedCourseOfferingId: string;

	@observable
	@attribute({ isReference: true })
	public completedByGeneratedPlan: Models.GeneratedPlanEntity;

	@observable
	@attribute({ isReference: true })
	public generatedCompletedCourseOffering: Models.CourseOfferingEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICompletedByGeneratedPlanGeneratedCompletedCourseOfferingAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.completedByGeneratedPlanId) {
				this.completedByGeneratedPlanId = attributes.completedByGeneratedPlanId;
			}
			if (attributes.generatedCompletedCourseOfferingId) {
				this.generatedCompletedCourseOfferingId = attributes.generatedCompletedCourseOfferingId;
			}

			if (attributes.completedByGeneratedPlan) {
				if (attributes.completedByGeneratedPlan instanceof Models.GeneratedPlanEntity) {
					this.completedByGeneratedPlan = attributes.completedByGeneratedPlan;
					this.completedByGeneratedPlanId = attributes.completedByGeneratedPlan.id;
				} else {
					this.completedByGeneratedPlan = new Models.GeneratedPlanEntity(attributes.completedByGeneratedPlan);
					this.completedByGeneratedPlanId = this.completedByGeneratedPlan.id;
				}
			} else if (attributes.completedByGeneratedPlanId !== undefined) {
				this.completedByGeneratedPlanId = attributes.completedByGeneratedPlanId;
			}

			if (attributes.generatedCompletedCourseOffering) {
				if (attributes.generatedCompletedCourseOffering instanceof Models.CourseOfferingEntity) {
					this.generatedCompletedCourseOffering = attributes.generatedCompletedCourseOffering;
					this.generatedCompletedCourseOfferingId = attributes.generatedCompletedCourseOffering.id;
				} else {
					this.generatedCompletedCourseOffering = new Models.CourseOfferingEntity(attributes.generatedCompletedCourseOffering);
					this.generatedCompletedCourseOfferingId = this.generatedCompletedCourseOffering.id;
				}
			} else if (attributes.generatedCompletedCourseOfferingId !== undefined) {
				this.generatedCompletedCourseOfferingId = attributes.generatedCompletedCourseOfferingId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
